// Inter
@font-face {
    font-family:  'Rubik';
    font-weight:  400;
    font-display: swap;
    src:          url('/fonts/Rubik/static/Rubik-Regular.ttf') format('truetype');
}


@font-face {
    font-family:  'Rubik';
    font-weight:  600;
    font-display: swap;
    src:          url('/fonts/Rubik/static/Rubik-SemiBold.ttf') format('truetype');
}


@font-face {
    font-family:  'Rubik';
    font-weight:  700;
    font-display: swap;
    src:          url('/fonts/Rubik/static/Rubik-Bold.ttf') format('truetype');
}



// Roboto Mono for font-family: 'Roboto Mono', monospace;
@font-face {
    font-family:  'Roboto Mono';
    font-weight:  400;
    font-display: swap;
    src:          url('/assets/fonts/Roboto_Mono/static/RobotoMono-Regular.ttf') format('truetype');
}


@font-face {
    font-family:  'Roboto Mono';
    font-weight:  500;
    font-display: swap;
    src:          url('/assets/fonts/Roboto_Mono/static/RobotoMono-Medium.ttf') format('truetype');
}

@font-face {
    font-family:  'Roboto Mono';
    font-weight:  600;
    font-display: swap;
    src:          url('/assets/fonts/Roboto_Mono/static/RobotoMono-SemiBold.ttf') format('truetype');
}
